
@import url('https://fonts.googleapis.com/css?family=Comfortaa');

body{
    background: #192e3a !important;
    color:#c4c9cd!important;
    font-family: "Comfortaa" !important;


}
@import url('https://fonts.googleapis.com/css?family=Roboto');
.header-nav a{
  font-family: "Comfortaa", sans-serif !important;
}
.site-header{
  height: 120px !important;
}

.site-header-inner{
  height: 120px !important;
}

@media (max-width: 640px){
.header-nav-toggle + .header-nav .header-nav-inner {
    flex-direction: column;
    padding: 24px;
    background-color: #15262f !important;
    border-bottom: 2px solid #13676c;
}
#logo{
  display:inline-block !important;
  margin: 0 auto !important;
  position: relative;
  left: 14px !important;
}

#submit {
    display: flex !important;
    justify-content: center !important;
}
h1, .h1 {
    font-size: 27px !important;
    line-height: 38px !important;
  }
  h2, .h2 {
      font-size: 26px !important;
      line-height: 32px!important;
    }
}
@media (min-width: 640px){
.header-nav ul:first-of-type {
    flex-wrap: wrap;
    position: absolute;
    left: 50%;
    width: 590px !important;
    left: 50% !important;
    transform: translate(-50%, -50%)!important;
  }
  .header-nav a:not(.button) {
    display: block;
    color: #9CA9B3;
    font-weight: 500;
    padding: 0 20px !important;
}

.mb-4 {
    margin-bottom: 4px;
    margin-left: 100px !important;
    margin-right: 100px !important;
}
}
.site-header + .site-content .section:first-of-type {
    padding-top: 120px !important;
}
.text-color-primary {
    color: #1c959c !important;
}
.features-tiles-item-image {
    background-color: #241d25 !important;
}
.site-footer::before {

    background: #241d25 !important;
  }
  .testimonial .tiles-item-inner {
      background: #241d25 !important;
  }
.card{
  margin-top:100px!important;
  background-color: #d6d6d6 !important;
}
#button2{
  background-color: #13676c !important;
}
button{
  background-color: #629a8700 !important;
}
button.btn.btn-danger-gradiant.mt-3.mb-3.text-white.border-0.py-2.px-3 {
    font-size: 16px !important;

}
@font-face {
  font-family: "iAWriterQuattroV";
  src: url(fonts/iAWriterQuattroV.ttf);

}
.testimonial-item-link a {
    color: #6fc6aa !important;
    text-decoration: none;
}
.form-control:focus {
    color: #495057;
    background-color: #fff !important;
    border-color: #6fc6aa !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(111 198 170 / 31%) !important;
}
.form-control{
  background-color: #dedddd!important;
}
.row{
  justify-content: center !important;
}
header.site-header.reveal-from-bottom.is-revealed {
    background-color: #15262f !important;
}
.header-nav a:not(.button){
  cursor: pointer;
}
.split-wrap .split-item {
  order:1 !important;
}
p.m-0 {
    font-size: 19px;
}

@media (max-width: 641px){
  p.m-0 {
      text-align: justify;
      padding-right: 5%;
      padding-left: 5%;
  }
}

@media (min-width: 821px)
{
.container .split-wrap .split-item .split-item-image {
    min-width: 528px;
    padding-right: 60px;
}
}
@media (max-width: 641px)
{
.split-wrap.invert-mobile .split-item .split-item-content {
    order: 0 !important;
  }}

  @media (min-width: 641px)
{
.split-wrap:not(.invert-desktop) .split-item:nth-child(even) .split-item-content, .split-wrap.invert-desktop .split-item:nth-child(odd) .split-item-content {
    order: 1;
    margin-left: 47px !important;
    margin-right: 0;
  }
  #third{
    margin-left: 34px;
  }
}
  @media (max-width: 641px){
    #third{
      margin-top: 40px;
    }
  }
  .container-sm {
      max-width: 944px;
      position: absolute;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
  }
  #headerhero{
    font-size: 24px !important;
  }
  p.m-0 {
    font-size: 18px !important;
  }
  @media (max-width: 1121px){
    .backimage{
      opacity:0.5;
      min-height: 500px;
      background-size: cover;

    }
    .container-sm {
    max-width: 944px;
    position: absolute;
    z-index: 1
}
  }
  @media (max-width: 600px){
    .backimage{
      margin-top: 250px;
      opacity:1;
      min-height: auto;
    }
    p.m-0 {
      font-size: 18px !important;
    }
  }
  h1.mt-0.mb-16.reveal-from-bottom.is-revealed {
      font-size: 70px;
  }
  .swal2-styled.swal2-confirm {
    border: 0;
    border-radius: .25em;
    background: initial;
    background-color: #7066e0 !important;
    color: #fff;
    font-size: 1em;
}
